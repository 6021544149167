import { LoadingOverlay } from 'components/Common/Spinner';
import { Paths } from 'configurations/paths/paths';
import ErrorPage from 'layouts/ErrorPage';
import React, { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';

const Oauth2Verify = lazy(() => import('pages/Auth/Oauth2Verify'));
const GetCompanyInfoFromSingpass = lazy(
  () => import('pages/Renter/MyProfile/GetCompanyInfoFromSingpass'),
);
const ResetPassword = lazy(() => import('pages/Auth/ResetPassword'));

export const Oauth2VerifyRouter = {
  path: Paths.OAUTH2_VERIFY,
  element: <Oauth2Verify />,
  errorElement: <ErrorPage />,
  children: [],
};

export const SingpassVerifyRegisterRouter = {
  // path: Paths.VERIFY_COMPANY_INFO_FROM_SINGPASS,
  path: '/my-info/new/verify',
  element: <GetCompanyInfoFromSingpass />,
  errorElement: <ErrorPage />,
  children: [],
};

export const SingpassVerifyUpdateRouter = {
  // path: Paths.VERIFY_COMPANY_INFO_FROM_SINGPASS,
  path: '/my-info/update/verify',
  element: <GetCompanyInfoFromSingpass />,
  errorElement: <ErrorPage />,
  children: [],
};

export const ResetPasswordRouter = {
  path: '',
  element: (
    <React.Suspense fallback={<LoadingOverlay loading={true} />}>
      <Outlet />
    </React.Suspense>
  ),
  errorElement: <ErrorPage />,
  children: [{ path: Paths.RESET_PASSWORD, element: <ResetPassword /> }],
};
