import { PaginationConfig, SPACE_TYPES } from 'configurations/constants/globalConstants';

export interface NavbarProps {
  themeNav?: string;
}

export interface SearchParams {
  address?: string;
  startDate?: any;
  endDate?: any;
  spaceType?: SPACE_TYPES;
  spaceSize?: string;
  fromPrice?: number;
  toPrice?: number;
  page?: number;
  size?: number;
  currentLongitude?: number;
  currentLatitude?: number;
  priceSort?: 'ASC' | 'DESC';
  locationSort?: 'ASC' | 'DESC';
  ratingSort?: 'ASC' | 'DESC';
  amenities?: string;
  rating?: string;
  securityMeasures?: string;
  additionalServices?: string;
  requiredQuantity?: number | string;
  zone?: 'city' | 'south' | 'west' | 'east' | 'north' | 'central'

  // params just for storing, not using for querying
  originalDates?: string[];
}

export const initialSearchParams = {
  page: PaginationConfig.PageIndex,
  size: PaginationConfig.PerPage,
};
