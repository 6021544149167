import axios from 'axios';
import { SHARE_RIGHT_BASE } from 'configurations/constants/globalConstants';
import { LoginPayLoad, RefreshAccessTokenPayload, RegisterPayload } from 'models/Request.model';

class AuthService {
  refreshAccessToken = (payload: RefreshAccessTokenPayload) => {
    return axios({
      url: `${SHARE_RIGHT_BASE.PrefixApi}/user/refresh`,
      method: 'POST',
      data: payload,
    });
  };
  register = (payload: RegisterPayload) => {
    return axios({
      url: `${SHARE_RIGHT_BASE.PrefixApi}/user/register`,
      method: 'POST',
      data: payload,
    });
  };

  login = (payload: LoginPayLoad) => {
    return axios({
      url: `${SHARE_RIGHT_BASE.PrefixApi}/user/sign_in`,
      method: 'POST',
      data: payload,
    });
  };
}

export const authService = new AuthService();
