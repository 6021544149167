// Write code for API path
export enum Paths {
  landingPage = '/landing-page',
  //
  OAUTH2_VERIFY = '/oauth2/verify',
  VERIFY_COMPANY_INFO_FROM_SINGPASS = '/my-info/new/verify',
  RESET_PASSWORD='/password/reset'
}
export enum OWNER_PATH {
  OWNER = '/owner',
  LANDING_PAGE = 'landing-page',
  SPACE_DETAILS = 'space-details',
  DASHBOARD = 'dashboard',
  BOOKING_DETAILS = 'booking-details',
  RATE_RENTER = 'rate-renter',
  USER_PROFILE = 'user-profile',
  INVOICE = 'invoice',
}

export enum RENTER_PATH {
  RENTER = '/',
  LANDING_PAGE = 'landing-page',
  ITEM_LIST = 'item-list',
  ITEM_DETAILS = 'item-details',
  PAYMENT = 'payment',
  CONFIRMATION = 'confirmation',
  PAYMENT_SUCCESS = 'payment-success',
  PAY = 'pay',
  MY_BOOKING = 'my-booking',
  MY_PROFILE = "my-profile",
  REVIEW = 'review',
  INVOICE = 'invoice',
  COMPANY_PROFILE = 'company-profile',
  // UPDATE_PROFILE = 'my-info/new/verify'
}
