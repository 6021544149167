import './App.css';
import { RouterProvider } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18n/i18n';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'theme/Theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackbarProvider } from 'notistack';
import { router } from 'routes/RenderRouter';
import { ToastContainer } from 'components/Common/ToastContainer';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingOverlay } from 'components/Common/Spinner';
import Coordination from 'utils/Coordination';
import { Libraries, useLoadScript } from '@react-google-maps/api';
import { useEffect } from 'react';
// import timezone from 'dayjs/plugin-timezone.js';
// import utc from 'dayjs/plugin-utc.js'; // often needed along with timezone
// import dayjs from 'dayjs';

// dayjs.extend(utc);
// dayjs.extend(timezone);

// // Now set the default timezone
// (dayjs as any).tz.setDefault("Etc/GMT+8");


const libraries: Libraries = ['places'];

function App() {
  Coordination();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || '',
    libraries,
  });

  useEffect(() => {
    if (!isLoaded) {
      console.log('Google Maps API not loaded');
    } else {
      console.log('Google Maps API loaded');
    }
  }, [isLoaded]);

  useEffect(() => {
    // Create a script element for the TrustLogo script
    const trustLogoScript = document.createElement('script');
    trustLogoScript.type = 'text/javascript';
    trustLogoScript.innerHTML = `
      TrustLogo(
        'https://www.positivessl.com/images/seals/positivessl_trust_seal_lg_222x54.png',
        'POSDV',
        'none'
      );
    `;

    // Find the target div with the specified ID and append the script to it
    const targetDiv = document.getElementById('myDiv');
    if (targetDiv) {
      targetDiv.appendChild(trustLogoScript);
    }

    // Clean up the script when the component unmounts
    return () => {
      if (targetDiv) {
        targetDiv.removeChild(trustLogoScript);
      }
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <LoadingOverlay />
      <I18nextProvider i18n={i18n}>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <LocalizationProvider dateAdapter={AdapterDateFns} localeText={{}}>
          <SnackbarProvider maxSnack={5} />
          {/* RENDER ROUTER HERE */}
          {/* ADD AUTH RPOVIDER */}
          <RouterProvider router={router} />
          {/* <Chat /> */}
        </LocalizationProvider>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
