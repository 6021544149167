import { API_BASE_URL, PREFIX_API, SHARE_RIGHT_HOST } from 'environments/react.config';

// save keys for local storage
export enum LOCAL_STORAGE_KEYS {
  AccessToken = 'token',
  RefreshToken = 'refreshToken',
}

export enum SESSION_STORAGE_KEYS {
  StoredItem = 'storedItem',
  ContractId = 'contractId',
  PaymentType = 'payment_type',
  ContractPayload = 'contractPayload',
}

// save endpoint using in ShareRight app
export const SHARE_RIGHT_BASE = {
  ApiBaseUrl: API_BASE_URL,
  PrefixApi: PREFIX_API,
  SR_HOST: SHARE_RIGHT_HOST
};

// save common constants
export enum COMMON_CONSTANTS {
  PrefixServerError = '5',
  OTPTimeOut = 90,
}

// ShareRight role
export enum SHARE_RIGHT_ROLE {
  User = 'ROLE_USER', // Renter
  Owner = 'ROLE_OWNER',
}

export enum SHARE_RIGHT_THEME {
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum SPACE_TYPES {
  All = 'ALL',
  Storage = 'STORAGE',
  Office = 'OFFICE',
  Warehouse = 'WAREHOUSE',
  HotDesking = 'HOTDESKING',
}

export enum SPACE_SIZE {
  SMALL = 'SMALL',
  LARGE = 'LARGE',
  EXTRA_LARGE = 'EXTRA_LARGE',
  EXTRA_EXTRA_LARGE = 'EXTRA_EXTRA_LARGE',
}

export enum PaginationConfig {
  PageIndex = 1,
  PerPage = 8,
  TotalPages = 10,
}

export enum MerchantStatus {
  NEW = 'NEW',
  ONBOARDING = 'ONBOARDING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ItemStatus {
  ACTIVE = 'ACTIVE',
  NON_ACTIVE = 'NON-ACTIVE',
}

export enum OwnerContractStatus {
  WAITING_TO_TRANSFER = 'WAITING_TO_TRANSFER',
  WAITING_TO_CONFIRM = 'WAITING_TO_CONFIRM',
  BEING_USED = 'BEING_USED',
  ON_GOING = 'ONGOING',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export enum RenterContractStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  UPCOMING = 'UPCOMING',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export enum AccountStatus {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
}
