import { Client, IMessage } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

const createSocket = () => new SockJS(process.env.REACT_APP_SOCKET_HOST);
let stompClient: Client | null = null;
let globalToken = '';

const getAuthHeaders = () => ({ 'X-Authorization': `Bearer ${globalToken}` });

const connectStompClient = (token: string) => {
  globalToken = token;

  if (!stompClient) {
    stompClient = new Client({
      webSocketFactory: createSocket,
      debug: console.log,
      connectHeaders: getAuthHeaders(),
    });
    stompClient.onConnect = () => {
      console.log('STOMP client successfully connected.');
    };

    stompClient.onStompError = (error) => {
      console.error('STOMP error:', error);
    };

    stompClient.activate();
  }
};

const disconnectStompClient = () => {
  if (stompClient && stompClient.active) {
    stompClient.deactivate();
  }
};

const subscribeToTopic = (topic: string, callback: (message: IMessage) => void): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (!stompClient) {
      reject(new Error("STOMP client is not initialized."));
      return;
    }

    const handleConnect = () => {
      stompClient!.subscribe(topic, callback, getAuthHeaders());
      console.log(`Successfully subscribed to topic: ${topic}`);
      stompClient!.onConnect = null; // Clear the handler to avoid multiple calls
      resolve();
    };

    const handleError = (error: any) => {
      console.error('STOMP error:', error);
      stompClient!.onStompError = null; // Clear the handler to avoid multiple calls
      reject(error);
    };

    stompClient.onConnect = handleConnect;
    stompClient.onStompError = handleError;

    if (!stompClient.active) {
      connectStompClient(globalToken);
    } else {
      handleConnect(); // Directly handle the subscription if the client is already connected.
    }
  });
};

const sendMessage = (destination: string, message: any) => {
  if (stompClient && stompClient.connected) {
    publishMessage(destination, message);
  } else {
    connectStompClient(globalToken);

    if (stompClient) {
      // Once connected, send the message
      stompClient.onConnect = () => {
        publishMessage(destination, message);
      };
    }
  }
};

const publishMessage = (destination: string, message: any) => {
  if (stompClient) {
    stompClient.publish({
      destination,
      body: JSON.stringify(message),
      headers: getAuthHeaders(),
    });
  }
};

export { connectStompClient, disconnectStompClient, subscribeToTopic, sendMessage };
