import { LoadingOverlay } from 'components/Common/Spinner';
import { RENTER_PATH } from 'configurations/paths/paths';
import React, { lazy } from 'react';

//LAZY LOADING COMPONENT
const BaseLayoutRenter = lazy(() => import('pages/Renter/BaseLayout/BaseLayout'));
const RenterLandingPage = lazy(() => import('pages/Renter/LandingPage'));
//
const RenterItemList = lazy(() => import('pages/Renter/ItemList'));
const RenterItemDetails = lazy(() => import('pages/Renter/ItemList/ItemDetails'));
//
const RenterPayment = lazy(() => import('pages/Renter/Payment'));
const RenterPaymentConfirmation = lazy(() => import('pages/Renter/Payment/Confirmation'));
const RenterPaymentPay = lazy(() => import('pages/Renter/Payment/Pay'));
const RenterPaymentSuccess = lazy(() => import('pages/Renter/Payment/PaymentSuccess'));
//
const RenterMyBooking = lazy(() => import('pages/Renter/MyBooking'));
const RenterBookingList = lazy(() => import('pages/Renter/MyBooking/BookingList'));
const RenterBookDetail = lazy(() => import('pages/Renter/MyBooking/BookingDetail'));
const RenterBookDetailItem = lazy(
  () => import('pages/Renter/MyBooking/BookingDetail/BookingDetailItem'),
);
//
const RenterProfile = lazy(() => import('pages/Renter/MyProfile'));
const RenterReview = lazy(() => import('pages/Renter/Review'));
const RenterInvoice = lazy(() => import('pages/Renter/Invoice'));
const CompanyProfile = lazy(() => import('pages/Renter/CompanyProfile'));

export const RenterRouter = {
  path: '',
  element: (
    <React.Suspense fallback={<LoadingOverlay loading={true} />}>
      <BaseLayoutRenter />
    </React.Suspense>
  ),
  children: [
    { path: RENTER_PATH.RENTER, element: <RenterLandingPage /> },
    {
      path: `${RENTER_PATH.ITEM_LIST}`,
      element: <RenterItemList />,
    },
    {
      path: `${RENTER_PATH.ITEM_DETAILS}/:id`,
      element: <RenterItemDetails />,
    },
    {
      path: RENTER_PATH.PAYMENT,
      element: (
        <React.Suspense fallback={<LoadingOverlay loading={true} />}>
          <RenterPayment />
        </React.Suspense>
      ),
      children: [
        {
          path: `${RENTER_PATH.CONFIRMATION}`,
          element: <RenterPaymentConfirmation />,
        },
        {
          path: `${RENTER_PATH.PAY}`,
          element: <RenterPaymentPay />,
        },
        {
          path: `${RENTER_PATH.PAYMENT_SUCCESS}`,
          element: <RenterPaymentSuccess />,
        },
      ],
    },
    {
      path: `${RENTER_PATH.MY_BOOKING}`,
      element: (
        <React.Suspense fallback={<LoadingOverlay loading={true} />}>
          <RenterMyBooking />
        </React.Suspense>
      ),
      children: [
        {
          path: ``,
          element: <RenterBookingList />,
        },
        {
          path: `:id`,
          element: (
            <React.Suspense fallback={<LoadingOverlay loading={true} />}>
              <RenterBookDetail />
            </React.Suspense>
          ),
          children: [
            {
              path: ``,
              element: <RenterBookDetailItem />,
            },
            {
              path: `${RENTER_PATH.REVIEW}`,
              element: <RenterReview />,
            },
            {
              path: `${RENTER_PATH.INVOICE}`,
              element: <RenterInvoice />,
            },
            {
              path: `${RENTER_PATH.COMPANY_PROFILE}/:ownerId`,
              element: <CompanyProfile />
            },
          ],
        },
      ],
    },
    {
      path: `${RENTER_PATH.MY_PROFILE}`,
      element: <RenterProfile />
    },
  ],
};
