export const primary = '#f36f3f'; //orange
export const primaryhight = '#fef1ec';
export const bgPrimaryHight = '#FCFAF9';
export const yellow = '#F2AA09';
export const yellowStar = '#F5BF00';
// white
export const white = '#FFFFFF';
// black
export const bgGreyBox = '#F8F8F8';
export const b9 = '#272727';
export const b7 = '#656B7F';
export const b6 = '#A1A4AC';
export const b5 = '#98A1B0';
export const b4 = '#D9D9D9';
export const b2 = '#EAEBEB';
export const line = '#EBEBEC';
export const boderline = '#ECECEC';
export const boderline1 = '#B2B8CE';
export const blueText = '#25597B';
export const negative1 = '#E83017';
export const positive1 = '#50A65C';
export const blueSteel = '#4CB9BD26';
export const textBlueSteel = '#25597B';
export const bgHightGreen = '#EBF5EF';
export const placeholder = '#a2a2a2ab';
export const active = '#FFF5F0';
export const errorMsg = '#d32f2f';

// 
export const boxShadowCard = '0px 4px 20px 5px rgba(0, 0, 0, 0.04)'