import ErrorPage from 'layouts/ErrorPage';
import React, { lazy } from 'react';
import { LoadingOverlay } from 'components/Common/Spinner';
import { OWNER_PATH } from 'configurations/paths/paths';
import { Navigate, Outlet } from 'react-router-dom';

//LAZY LOADING COMPONENT
const BaseLayoutOwner = lazy(() => import('pages/Owner/BaseLayout/BaseLayout'));
const OwnerLandingPage = lazy(() => import('pages/Owner/LandingPage'));
const SpaceDetails = lazy(() => import('pages/Owner/SpaceDetails/SpaceDetails'));
const BookingDetails = lazy(() => import('pages/Owner/BookingDetails/BookingDetails'));
const MyDashboard = lazy(() => import('pages/Owner/MyDashboard/MyDashboard'));
const RateRenter = lazy(() => import('pages/Owner/RateRenter/RateRenter'));
const InvoiceOwner = lazy(() => import('pages/Owner/Invoice'));
const UserProfile = lazy(() => import('pages/Owner/UserProfile'));

export const OwnerRouter = {
  path: OWNER_PATH.OWNER,
  element: (
    <React.Suspense fallback={<LoadingOverlay loading={true} />}>
      <BaseLayoutOwner />
    </React.Suspense>
  ),
  errorElement: <ErrorPage />,
  children: [
    {
      path: OWNER_PATH.OWNER,
      element: <Navigate to={`${OWNER_PATH.LANDING_PAGE}`} replace />,
    },
    { path: OWNER_PATH.LANDING_PAGE, element: <OwnerLandingPage /> },
    { path: OWNER_PATH.SPACE_DETAILS, element: <SpaceDetails /> },
    { path: OWNER_PATH.DASHBOARD, element: <MyDashboard /> },
    {
      path: `${OWNER_PATH.BOOKING_DETAILS}/:contractId`,
      element: (
        <React.Suspense fallback={<LoadingOverlay loading={true} />}>
          <Outlet />
        </React.Suspense>
      ),
      // element: <BookingDetails />,
      children: [
        {
          path: ``,
          element:  <BookingDetails />,
        },
        {
          path: OWNER_PATH.RATE_RENTER,
          element: <RateRenter />,
        },
        {
          path: `${OWNER_PATH.USER_PROFILE}/:renterId`,
          element: <UserProfile />,
        },
        {
          path: `${OWNER_PATH.INVOICE}`,
          element: <InvoiceOwner />,
        },
      ],
    },
  ],
};
