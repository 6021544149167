/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { createContext, useContext, useMemo } from 'react';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { LOCAL_STORAGE_KEYS } from 'configurations/constants/globalConstants';
import { RENTER_PATH, OWNER_PATH, Paths } from 'configurations/paths/paths';
import { resetAppState, setCredentialInfo } from 'redux/Reducer/reducer';
import { CredentialInfo, Token } from 'models/CredentialInfo.model';
import { authService } from 'services/AuthService';
import { useLocation, useNavigate } from 'react-router-dom';
import { RefreshTokenResponse } from 'models/Response.model';
import { connectStompClient } from 'utils/stomp';

interface AuthContextValue {
  token: Token;
  saveToken: (data: Token) => void;
  logout: () => void;
}

// create context
const AuthContext = createContext<AuthContextValue | undefined>(undefined);

function AuthProvider({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [token, setToken] = useState<Token>(() => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.AccessToken);
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEYS.RefreshToken);

    return (accessToken && refreshToken && { accessToken, refreshToken }) || null;
  });

  const saveToken = (data: Token) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.AccessToken, data.accessToken);
    localStorage.setItem(LOCAL_STORAGE_KEYS.RefreshToken, data.refreshToken);
    setToken(data);
  };

  const logout = () => {
    const isOwner = location.pathname.startsWith('/owner/');
    const path = isOwner ? `${OWNER_PATH.OWNER}/${OWNER_PATH.LANDING_PAGE}` : RENTER_PATH.RENTER;
    // reset token
    setToken(null);
    // clear all storage
    localStorage.clear();
    sessionStorage.clear();
    // clear app state
    dispatch(resetAppState('resetAppState'));
    // back to home page
    navigate(path, { replace: true });
  };

  useEffect(() => {
    if (token) {
      const { exp, role, userId } = jwtDecode(token.accessToken) as any;
      if (Date.now() >= exp * 1000) {
        if (!token.refreshToken) {
          logout();
          return;
        }
        authService
          .refreshAccessToken({ refreshToken: token.refreshToken })
          .then((res) => {
            const data = res as unknown as RefreshTokenResponse;
            if (data) {
              localStorage.setItem(LOCAL_STORAGE_KEYS.AccessToken, data.accessToken);
              localStorage.setItem(LOCAL_STORAGE_KEYS.RefreshToken, data.refreshToken);
              setToken({
                accessToken: data.accessToken,
                refreshToken: data.refreshToken,
              });
            }
          })
          .catch((error) => {
            logout();
          });
      } else {
        const info: CredentialInfo = {
          exp,
          role,
          acessToken: token.accessToken,
          userId,
        };
        dispatch(setCredentialInfo<CredentialInfo>(info));
        connectStompClient(token.accessToken);
      }
    } else {
      const isOwner = location.pathname.startsWith('/owner/');
      if (isOwner) {
        navigate(`${OWNER_PATH.OWNER}/${OWNER_PATH.LANDING_PAGE}`, { replace: true });
        return;
      }
      console.log('location.pathname: ', location.pathname);

      if (
        location.pathname.includes(RENTER_PATH.ITEM_LIST) ||
        location.pathname.includes(RENTER_PATH.ITEM_DETAILS)
      ) {
        navigate(location.pathname);
        return;
      }
      if (location.pathname.includes(Paths.RESET_PASSWORD)) {
        return;
      }
      navigate(RENTER_PATH.RENTER, { replace: true });
    }
  }, [token?.accessToken, token?.refreshToken]);

  const value = useMemo(
    () => ({
      token,
      saveToken,
      logout,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;

export const AuthConsumer = () => {
  return useContext(AuthContext);
};
