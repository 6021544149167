import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { SHARE_RIGHT_BASE } from '../constants/globalConstants';
import { COMMON_CONSTANTS, LOCAL_STORAGE_KEYS } from 'configurations/constants/globalConstants';
import { authService } from 'services/AuthService';
import { AuthConsumer } from 'pages/Auth/AuthProvider';
import { useLocation } from 'react-router-dom';
import { RefreshTokenResponse } from 'models/Response.model';

function Interceptor() {
  const authContext = AuthConsumer();
  const location = useLocation();

  let isRefreshing = false;
  let failedQueue = [];

  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    failedQueue = [];
  };

  // Handle function
  const onRequestSuccess = (config: InternalAxiosRequestConfig<any>) => {
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.AccessToken);
    config.headers['Authorization'] = token && `Bearer ${token}`;
    return config;
  };

  const onRequestError = (error) => {
    const errMessage = error.response?.data || error?.response || error;
    return Promise.reject(errMessage);
  };

  const onResponseSuccess = (response: AxiosResponse) => {
    return Promise.resolve(response.data);
  };

  const onResponseError = (error: AxiosError) => {
    if (error.response?.status?.toString().startsWith(COMMON_CONSTANTS.PrefixServerError)) {
      return Promise.reject({
        code: error.response.status,
        message: 'Internal Server Error!!!',
      });
    }

    const originalRequest = error.config as any;
    const errMessage = error.response?.data || error?.response || error;

    // Check 401 status code with refresh token api
    if (error?.response?.status === 401 && originalRequest.url.includes(`/refresh`)) {
      processQueue(error, null);
      // localStorage.clear();
      // window.location.reload();
      // return Promise.reject({
      //   code: 401,
      //   message: 'Unauthorized!!! Please sign in to use Share Right services',
      // });
      authContext.logout();
      return Promise.reject();
    }

    // Check status request failed to refresh token
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        const refreshAccessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.RefreshToken);
        if (!refreshAccessToken) {
          authContext.logout();
          return reject({
            code: 401,
            message: 'Unauthorized!!! Please sign in to use ShareRight services',
          });
        }

        return authService
          .refreshAccessToken({ refreshToken: refreshAccessToken })
          .then((res) => {
            const { accessToken, refreshToken } = res as any as RefreshTokenResponse;
            if (accessToken) {
              authContext.saveToken({
                accessToken: accessToken,
                refreshToken: refreshToken,
              });
              axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
              originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
              processQueue(null, accessToken);
              resolve(axios(originalRequest));
            } else {
              authContext.logout();
              reject({
                code: 401,
                message: 'Unauthorized!!! Please sign in to use ShareRight services',
              });
            }
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(errMessage);
  };

  // Handle axios default config
  axios.defaults.baseURL = `${SHARE_RIGHT_BASE.ApiBaseUrl}`;

  // Handle axios request
  axios.interceptors.request.use(onRequestSuccess, onRequestError);

  // Handle axios response
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
}

export default Interceptor;
