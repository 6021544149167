import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { SHARE_RIGHT_ROLE } from 'configurations/constants/globalConstants';
import { Message } from 'models/Chat.model';
import {
  CredentialInfo,
  CredentialInfoDefault,
  Location,
  OTPCode,
  OTPDefault,
} from 'models/CredentialInfo.model';
import { SearchParams, initialSearchParams } from 'models/NavbarProps.model';
import { ContractPayload } from 'models/Request.model';
import { CompanyInfoFromSingpass } from 'models/Response.model';

interface AppState {
  initCallInterceptor: boolean;
  loading: boolean;
  registerDialog: boolean;
  verifyEmailDialog: boolean;
  loginDialog: boolean;
  activeStep: number;
  credentialInfo: CredentialInfo;
  otp: OTPCode;
  searchParams: SearchParams;
  currentLocation: Location;
  paymentStepProcessing: number;
  currentRole: SHARE_RIGHT_ROLE;
  cacheSearchParamsQuery: SearchParams;
  groupMessages: Record<number, Message[]>;
  createBookingPayload: ContractPayload;
  s3PrivateKey: string;
  CompanyInfoFromSingpass: CompanyInfoFromSingpass;
  forgotPasswordDialog: boolean;
}

// Init state
const initialState: AppState = {
  initCallInterceptor: false,
  loading: false,
  registerDialog: false,
  verifyEmailDialog: false,
  loginDialog: false,
  activeStep: 1,
  credentialInfo: CredentialInfoDefault,
  otp: OTPDefault,
  searchParams: initialSearchParams,
  currentLocation: {},
  paymentStepProcessing: 0,
  currentRole: SHARE_RIGHT_ROLE.User,
  cacheSearchParamsQuery: initialSearchParams,
  groupMessages: {},
  createBookingPayload: null,
  s3PrivateKey: '',
  CompanyInfoFromSingpass: null,
  forgotPasswordDialog: false
};

// reducer callback
const reducers = {
  setInitCallInterceptor(state: AppState, action: PayloadAction<boolean>) {
    state.initCallInterceptor = action.payload;
  },
  setLoading(state: AppState, action: PayloadAction<boolean>) {
    state.loading = action.payload;
  },
  setRegisterDialog(state: AppState, action: PayloadAction<boolean>) {
    state.registerDialog = action.payload;
  },
  setVerifyEmailDialog(state: AppState, action: PayloadAction<boolean>) {
    state.verifyEmailDialog = action.payload;
  },
  setLoginDialog(state: AppState, action: PayloadAction<boolean>) {
    state.loginDialog = action.payload;
  },
  setActiveStep(state: AppState, action: PayloadAction<number>) {
    state.activeStep = action.payload;
  },
  setCredentialInfo(state: AppState, action: PayloadAction<CredentialInfo>) {
    state.credentialInfo = action.payload;
  },
  setOTP(state: AppState, action: PayloadAction<OTPCode>) {
    state.otp = action.payload;
  },
  setSearchParams(state: AppState, action: PayloadAction<SearchParams>) {
    state.searchParams = action.payload;
  },
  setCurrentLocation(state: AppState, action: PayloadAction<Location>) {
    state.currentLocation = action.payload;
  },
  setPaymentStepProcessing(state: AppState, action: PayloadAction<number>) {
    state.paymentStepProcessing = action.payload;
  },
  setCurrentRole(state: AppState, action: PayloadAction<SHARE_RIGHT_ROLE>) {
    state.currentRole = action.payload;
  },
  resetAppState(state: AppState, action: PayloadAction<any>) {
    if (action.payload === 'resetAppState') {
      Object.keys(initialState).forEach((key) => {
        if (key !== 'initCallInterceptor') {
          state[key] = initialState[key];
        }
      });
    }
  },
  cacheSearchParamsQuery(state: AppState, action: PayloadAction<SearchParams>) {
    state.cacheSearchParamsQuery = action.payload;
  },
  setGroupMessages(
    state: AppState,
    action: PayloadAction<{ groupId: number; messages: Message[] }>,
  ) {
    const { groupId, messages } = action.payload;

    // Ensure the group's message array exists, and append new messages
    state.groupMessages[groupId] = [...(state.groupMessages[groupId] || []), ...messages];
  },

  setCreateBookingPayload(state: AppState, action: PayloadAction<ContractPayload>) {
    state.createBookingPayload = action.payload;
  },

  setS3PrivateKey(state: AppState, action: PayloadAction<string>) {
    state.s3PrivateKey = action.payload;
  },

  setCompanyInfoFromSingpass(state: AppState, action: PayloadAction<CompanyInfoFromSingpass>) {
    state.CompanyInfoFromSingpass = action.payload;
  },
  setForgotPasswordDialog(state: AppState, action: PayloadAction<boolean>) {
    state.forgotPasswordDialog = action.payload;
  },
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers,
});

export const {
  setInitCallInterceptor,
  setLoading,
  setRegisterDialog,
  setVerifyEmailDialog,
  setLoginDialog,
  setActiveStep,
  setCredentialInfo,
  setOTP,
  setSearchParams,
  setCurrentLocation,
  setPaymentStepProcessing,
  setCurrentRole,
  resetAppState,
  cacheSearchParamsQuery,
  setGroupMessages,
  setCreateBookingPayload,
  setS3PrivateKey,
  setCompanyInfoFromSingpass,
  setForgotPasswordDialog,
} = appSlice.actions;

// Selector
const getGroupMessages = (state: { app: AppState }) => state.app.groupMessages;

export const selectInitCallInterceptor = (state: { app: AppState }) =>
  state.app?.initCallInterceptor;
export const appSelector = (state: { app: AppState }) => state.app;
export const selectLoading = (state: { app: AppState }) => state.app?.loading;
export const selectRegisterDialog = (state: { app: AppState }) => state.app?.registerDialog;
export const selectVerifyEmailDialog = (state: { app: AppState }) => state.app?.verifyEmailDialog;
export const selectLoginDialog = (state: { app: AppState }) => state.app?.loginDialog;
export const selectCredentialInfo = (state: { app: AppState }) => state.app?.credentialInfo;
export const selectOTP = (state: { app: AppState }) => state.app?.otp;
export const selectSearchParams = (state: { app: AppState }) => state.app?.searchParams;
export const selectCurrentLocation = (state: { app: AppState }) => state.app?.currentLocation;
export const selectPaymentStepProcessing = (state: { app: AppState }) =>
  state.app?.paymentStepProcessing;
export const selectCurrentRole = (state: { app: AppState }) => state.app?.currentRole;
export const selectCacheSearchParamsQuery = (state: { app: AppState }) =>
  state.app?.cacheSearchParamsQuery;
export const selectGroupMessages = createSelector(
  [getGroupMessages, (_, groupId: number) => groupId],
  (groupMessages, groupId): Message[] | null => {
    return groupMessages[groupId] || null;
  },
);
export const selectCreateBookingPayload = (state: { app: AppState }) =>
  state.app?.createBookingPayload;

export const selectS3PrivateKey = (state: { app: AppState }) => state.app?.s3PrivateKey;

export const selectCompanyInfoFromSingpass = (state: { app: AppState }) => state.app?.CompanyInfoFromSingpass;
export const selectForgotPasswordDialog = (state: { app: AppState }) => state.app?.forgotPasswordDialog;
