import Interceptor from 'configurations/axios/interceptor';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { selectInitCallInterceptor, setInitCallInterceptor } from 'redux/Reducer/reducer';

export default function Root() {
  const dispatch = useDispatch();
  const initCall = useSelector(selectInitCallInterceptor);

  if (!initCall) {
    Interceptor();
    dispatch(setInitCallInterceptor(true));
  }

  return <Outlet />;
}
