import { Location } from 'models/CredentialInfo.model';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentLocation } from 'redux/Reducer/reducer';

function Coordination() {
  const dispatch = useDispatch();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const location: Location = {
        currentLongitude: position.coords.longitude,
        currentLatitude: position.coords.latitude,
      };

      dispatch(setCurrentLocation(location));
    });
  }, []);
}

export default Coordination;
