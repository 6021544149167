import ErrorPage from 'layouts/ErrorPage';
import Root from 'layouts/Root';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import { RenterRouter } from './pages/RenterRouter';
import { OwnerRouter } from './pages/OwnerRouter';
import { ShareRightComponentRouter } from './pages/ShareRightComponentRouter';
import AuthProvider from 'pages/Auth/AuthProvider';
import {
  Oauth2VerifyRouter,
  ResetPasswordRouter,
  SingpassVerifyRegisterRouter,
  SingpassVerifyUpdateRouter,
} from './pages/CommonRouter';
import ScrollToTop from 'components/Common/ScrollToTop/ScrollToTop';

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthProvider>
        <ScrollToTop />
        <Root />
      </AuthProvider>
    ),
    errorElement: <ErrorPage />,
    children: [
      // ROUTE BUYER
      RenterRouter,
      // ROUTE SELLER
      OwnerRouter,
      //
      ShareRightComponentRouter,
      Oauth2VerifyRouter,
      SingpassVerifyRegisterRouter,
      SingpassVerifyUpdateRouter,
      ResetPasswordRouter,
    ],
  },
]);
