import { SHARE_RIGHT_ROLE } from 'configurations/constants/globalConstants';

export interface CredentialInfo {
  acessToken?: string;
  refreshToken?: string;
  role?: SHARE_RIGHT_ROLE;
  dashboardUrl?: string;
  exp?: number;
  userId?: string;
}

export const CredentialInfoDefault = {
  acessToken: '',
  refreshToken: '',
  role: SHARE_RIGHT_ROLE.User,
  dashboardUrl: '/',
  exp: -1,
  userId: '',
};

export interface Token {
  accessToken?: string;
  refreshToken?: string;
}

export interface OTPCode {
  otpId: number;
}

export const OTPDefault = {
  otpId: -1,
};

export interface Location {
  currentLongitude?: number;
  currentLatitude?: number;
}

export interface UserInfo {
  username: string;
  email: string;
  companyAddress: string;
  companyUenNumber: string;
  companyName: string;
  phoneNumber: string;
  status: string;
  password?: string;
  fullName?:string;
  avatar?:string;
  hasCompletedMyInfoBuz: boolean;
}
